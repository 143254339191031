import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { User } from '../types/user.type';

@Injectable({
    providedIn: 'root'
})
export class UserStorageService {
    private user: Subject<User | null> = new BehaviorSubject<User | null>(null);
    private loggedIn: boolean = false;

    constructor() {
        this.getUser().subscribe(user => {
            this.loggedIn = user !== null;
        })
    }

    public setUser(user: User | null) {
        if (user !== null){
            localStorage.setItem("token", user.token);
        }
        else {
            localStorage.removeItem("token");
        }
        this.user.next(user);
    }

    public getUser(): Observable<User | null> {
        return this.user.asObservable();
    }

    public isLoggedIn(){
        return this.loggedIn;
    }
}
