import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {EnterGuard} from './guards/enter.guard';
import {IsAuthenticatedGuard} from './guards/is-authenticated.guard';

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    canActivate: [
      EnterGuard
    ]
  },
  {
    path: 'folder',
    loadChildren: () => import('./idv/folder/folder.module').then(m => m.FolderPageModule),
    canActivate: [
      IsAuthenticatedGuard
    ]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate: [
      IsAuthenticatedGuard
    ]
  },
  {
    path: 'folder_details/:file_uid',
    loadChildren: () => import('./file-details/file-details.module').then( m => m.FileDetailsPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full',
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
