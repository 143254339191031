import {Component, Input, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {GetListService} from '../../services/get-list.service';
import {ToolsService} from '../../services/tools.service';
import * as moment from 'moment';
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-wiew-data',
  templateUrl: './wiew-data.component.html',
  styleUrls: ['./wiew-data.component.scss'],
})
export class WiewDataComponent implements OnInit {

  @Input() entity: any;
  @Input() code: any;
  @Input() idData: any;
  @Input() mode: string;
  title: string;
  dataList = [];
  uniqId = '';
  noValue = false;
  //stocke les paramètres des lists
  optionList = [];

  selectedItems = [];


  constructor(
    public getListService: GetListService,
    public toolsServive: ToolsService,
    private popoverController: PopoverController,
    public translateService: TranslateService,
  ) {
  }

  ngOnInit() {

    switch (this.mode.toLowerCase()) {

      case 'create':

        this.title = this.translateService.instant('GLOBAL.CREATE');
        this.createMode();

        break;
      case 'update':
        this.title = this.translateService.instant('GLOBAL.UPDATE');

        this.updateMode();

        break;
      default:

        break;

    }


  }

// Fonctions du menu déroulant + Select and DeSelect
  onItemSelect($event: any, key) {
    console.log($event, key);
    this.changeData(key, $event);

  }

  onSelectAll($event: any, key) {
    console.log($event, key);

    $event.forEach((element) => {

      this.changeData(key, element);
    });
  }

  onItemDeSelect($event: any, key) {
    console.log($event, key);
    this.changeData(key, $event, 'remove');

  }

  onDeSelectAll($event: any, key) {
    console.log('onDeSelectAll', $event, key);


    this.changeData(key, $event, 'allRemove');

  }


  closeModal() {
    this.popoverController.dismiss().then();
  }

  // Fin


  saveData() {
    // console.log('Enregitrement de ', this.uniqId,);
    // console.log('newObject', this.regenerateObjet());

    let hasError = false;
    document.querySelectorAll<HTMLInputElement>('.form-group input').forEach(input => {
      if(input.required && input.value === '') {
        hasError = true;
        const text = input.parentNode.querySelector('label').innerText.slice(0, -1);
        this.toolsServive.toast(text, 'danger');
      }
    });

    if(!hasError) {
      if(document.querySelectorAll('.input-error').length > 0) {
        return this.toolsServive.toast('Les champs ne sont pas conforme', 'danger');
      }

      this.getListService.updateData(this.entity, this.uniqId, this.regenerateObjet()).then(
        (value) => {

          this.getListService.getList(this.entity);
          this.toolsServive.toast('DASHBOARD.SUCCESS_SAVE', 'success');

          this.closeModal();
        },
        (err) => {
          console.log(err);
          this.toolsServive.toast('GLOBAL.ERROR', 'danger');
        }
      );
    }
  }

  regenerateObjet() {

    const objetTosave = {};


    for (const element of this.dataList) {

      //Si le champs deleted est null on le passe à false - utile lors de la création
      if(element.key ==='deleted' && element.value === null){

        element.value = false;
      }


      Object.assign(objetTosave, {[element.key]: element.value});
    }

    // console.log(objetTosave);
    return objetTosave;

  }

  /**
   * Permet la modification des valeurs quand changement dans les inputs
   *
   * @param key
   * @param value
   * @param action : 'add' pour ajouter des eleement et 'remove' pour les retirer Utiliser que pour les lists
   */
  changeData(key, $event, action = 'add') {

    this.dataList.forEach((element, index, array) => {

      if (element.key === key) {


        if ($event.target) {
          // pour les data input
          array[index].value = $event.target.value;
        } else {

          //pour les data list

          if (action === 'add') {
            console.log('++++');

            //on s'assure que le type soit défini en Array
            if(!array[index].value){
              array[index].value = [];
            }
            array[index].value.push($event);

          } else if (action === 'remove') {

            array[index].value.forEach((el, ind, arr) => {
              console.log('------');
              //Si le code match
              if (el.unique_id === $event.unique_id) {

                arr.splice(ind, 1);
              }
            });
          } else if (action === 'allRemove') {
            array[index].value.forEach((el, ind, arr) => {

              arr.splice(ind);

            });
          }
        }

      }

      // console.log('>>>>>>>>',this.dataList);

    });
  }

  /**
   *  Defini les paramétre du dropdown
   *
   * @param type
   */
  dropdownSettings(type) {

    let singleSelection = true;
    if (type === 'multi') {
      singleSelection = false;
    }

    return {
      singleSelection,
      idField: 'unique_id',
      textField: 'label',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout déselectionner',
      searchPlaceholderText: 'Rechercher',
      noDataAvailablePlaceholderText: 'Aucun résultat',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };
  }

  getSelectedItems(attribut) {

    const dataSelected = [];

    for (const selected of this.selectedItems) {

      if (selected.list === 'attribut') {

        dataSelected.push({unique_id: selected.code, label: selected.label});
      }

    }
    ;


    return dataSelected;


  }


  /**
   *  Genere des list dynamique
   *  Récupere les options pour former la liste
   *  REcupere les code et label de la list courante
   */
  generateList(key: string, value) {

    //Recupération des options pour les lists
    this.getListService.getOptionsListParams(this.entity, key).then(
      (option: any) => {

        //on utilise la key qui est en fait l'attribut comme index
        this.optionList[key] = [...option];
      }
    );

    //on récupére les default value
    /**
     * Permet de récupérer dynamiquement les code et label utilisé pour cette list
     */
    this.getListService.getCodeAndLabel(this.entity, key).then(
      (codeAndLabel: any) => {


        this.selectedItems[key] = [];
        //lorsque nous sommes dans une liste value prends la forme d'un tableau
        // @ts-ignore
        if(this.isIterable(value)) {
          for (const v of value) {

            this.selectedItems[key].push({unique_id: v[codeAndLabel.code], label: v[codeAndLabel.label]});
          }
        }
      }
    );
  }

  isIterable(obj) {
    // checks for null and undefined
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
  }


  /**
   * Permet d'initialiser les données dans le mode Update
   */
  updateMode() {

    this.getListService.dataListSubject.getValue()[this.code].rows.forEach(dataList => {

      if (dataList.id === this.idData) {

        for (let [key, value] of Object.entries(dataList)) {

          if (value == null || value === undefined) {
            this.noValue = true;
          }

          //stocke le uniue_id en variable de class
          if (key === 'unique_id') {
            this.uniqId = value.toString();
          }


        }
        this.generateDataToDisplay(dataList.unique_id);

      }

    });

    //Si l'uniqId est toujours vide il y a eu un problème
    if (this.uniqId === '') {
      this.toolsServive.toast('GLOBAL.ERROR', 'danger');
    }

  }

  /**
   * Permet d'initialiser les données dans le mode Create
   */
  createMode() {

    this.getListService.codeActifSubject.subscribe((code) => {

      this.entity = this.getListService.getEntityByCode(code);

      this.generateDataToDisplay(null);

      // this.getListService.getAttributEntity(this.entity).then((attributArray: any) => {
      //
      //
      //   // attributArray.forEach((attribut) => {
      //     this.generateDataToDisplay(null);
      //   // });
      //
      // });


    });

  }

  /**
   *  Cette fonction permet en fonction de formater la donnée dans son format HTML
   * @param key
   * @param value
   * @private
   */
  private generateDataToDisplay(uniqueId) {
    this.getListService.getTypeData(this.entity, uniqueId).then((htmlInformations: any) => {

      this.dataList = htmlInformations;

      for(const htmlInformation of this.dataList) {


        //caste les date au format HTML
        if (htmlInformation.type.toLowerCase() === 'date') {

          // Si la date n'est pas défini au met par defaut la date du jour
          if(!htmlInformation.value){
            htmlInformation.value = moment().format('y-MM-D');
          }

          htmlInformation.value = moment(htmlInformation.value).format('y-MM-D');
        }

        //gestion des list
        if (htmlInformation.type === 'multi' || htmlInformation.type === 'simple') {
          this.generateList(htmlInformation.key, htmlInformation.value);
        }
      }
    });

  }

  public testIfValidFormat(format: string,$event) {

    const inputValue = $event.target.value;
    let regex;
    if(format === 'mail') {
      regex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
    } else {
      regex = new RegExp('^(?:(?:\\+|00)33|0)\\s*[1-9](?:[\\s.-]*\\d{2}){4}');
    }

    if(regex.test(inputValue)) {
      $event.target.classList.remove('input-error');
    } else {
      $event.target.classList.add('input-error');
    }
  }
}
