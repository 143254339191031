import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-strong-password',
  templateUrl: './strong-password.component.html',
  styleUrls: ['./strong-password.component.scss'],
})
/**
 *  Pour appeler ce composant passer en paramétre le mot de passe saisie par l'utilisateur
 *    Exemple :
 *    <app-strong-password [pass]="user.password"></app-strong-password>
 */
export class StrongPasswordComponent implements OnInit, OnChanges {


  @Input() pass = '';


  constructor(public authService: AuthService) {
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {

  }

  changeIcon(bool) {

    if (bool) {
      return 'checkmark-outline';
    } else {

      return 'close-outline';
    }

  }


}
