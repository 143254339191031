import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {PopoverController} from '@ionic/angular';
import {AuthService} from 'src/app/services/auth.service';
import {ToolsService} from 'src/app/services/tools.service';
import {UserStorageService} from 'src/app/services/user-storage.service';
import {UsersService} from 'src/app/services/users.service';
import {globalConfig} from '../../types/config.type';
import {error} from "protractor";
import {catchError} from "rxjs/operators";

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss'],
})
export class ProfilComponent implements OnInit {

  hideAccount = true; // true
  hidePassword = false; // false
  isActive = true;
  user: any;
  actualPassword: string;
  password: string;
  rePassword: string;
  authorizeAfterControlTwoPassword = false;
  authorizeAfterControlActualPassword = false;

  userCanUpdate: boolean;

  constructor(
    private popoverController: PopoverController,
    private userStorageService: UserStorageService,
    private toolsService: ToolsService,
    private authService: AuthService,
    private usersService: UsersService,
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.userStorageService.getUser().subscribe((user) => {
      console.log(user);
      this.user = user;
      this.user.password = '';
      this.user.rePassword = '';
      this.userCanUpdate = !globalConfig.canUpdateProfil.includes(user.role[0].code);
      // Object.assign(this.user, {password: ''});

    });
  }

  closeModal() {
    this.popoverController.dismiss().then();
  }

  clickOnTab(clickedTabValue) {

    if (clickedTabValue === 'password') {

      this.hideAccount = false;
      this.hidePassword = true;
      this.isActive = false;

    } else if (clickedTabValue === 'account') {

      this.hideAccount = true;
      this.hidePassword = false;
      this.isActive = true;
    }

  }

  controlActualPassword() {

    if (this.actualPassword.length >= this.authService.passwordRuleSecu.size.size) {

      this.authService.testActualPassWord(this.actualPassword).then(
        (value: any) => {

          if (!value) {
            this.authorizeAfterControlActualPassword = false;
            this.toolsService.toast('PROFIL.CONTROL_ACTUAL_PASSWORD_ERROR', 'warning');
            return false;
          } else {

            //good password
            this.authorizeAfterControlActualPassword = true;

            return true;
          }

        },
        (err) => {
          console.error(err);
        }
      );
    }


  }

  controlTwoPassword() {

    if (this.user.password !== this.user.rePassword) {

      this.toolsService.toast('PROFIL.CONTROL_TWO_PASSWORD_ERROR"', 'warning');
      return false;

    } else if (this.user.password !== null && this.user.rePassword !== null) {

      const validPassword = this.authService.validPassword(this.user.password);
      console.log('validPassword', validPassword);
      if (!validPassword) {
        this.toolsService.toast('PROFIL.CONTROL_FORMAT_ERROR', 'warning');
      }

      return validPassword;

    } else {

      return true;
    }
  }

// Az123azer@
  updateInfoProfilUser() {


    console.log(this.user);
    this.usersService.updateUser(this.user).then(
      (value) => {
        this.toolsService.toast('GLOBAL.SUCCESS_SAVE', 'success').then();
      }, (err) => {
        console.log(err.error);
        this.toolsService.toast('GLOBAL.ERROR,', 'danger').then();
      });
  }


  changePassword() {
    console.log(this.authorizeAfterControlActualPassword);

    if (this.controlTwoPassword() && this.authorizeAfterControlActualPassword) {

      this.authService.changePassword(this.actualPassword, this.user.password).then(
        (value) => {

          if (value) {
            this.toolsService.toast('PROFIL.SUCCES_PASSWORD', 'success').then();
            this.authService.logout();
          }

        },
        (err) => {
          this.toolsService.toast('GLOBAL.ERROR', 'success').then();
        });

    }

  }
}
