import {Component, OnInit} from '@angular/core';
import {GetListService} from '../../../services/get-list.service';
import {menu} from '../../../types/menuItem.type';
import {PopoverController} from '@ionic/angular';
import {WiewDataComponent} from '../../../popover/wiew-data/wiew-data.component';
import {ToolsService} from '../../../services/tools.service';
import {ConfirmationComponent} from '../../../popover/confirmation/confirmation.component';


@Component({
  selector: 'app-result-component',
  templateUrl: './result-component.component.html',
  styleUrls: ['./result-component.component.scss'],
})
export class ResultComponentComponent implements OnInit {


  entity: any;
  code: any;
  list = [];
  grid = [];


  constructor(
    public getListService: GetListService,
    private popoverController: PopoverController,
    public toolsService: ToolsService
  ) {
  }


  ngOnInit() {

    //on recupere le entity consulté par l'utilisateurs
    this.getListService.codeActifSubject.subscribe((code) => {

      this.code = code;
      this.entity = this.getListService.getEntityByCode(code);

      //on recupére les datas pour cette entité
      this.getListService.dataListSubject.subscribe((data) => {


        if (data[code] !== undefined) {
          //on affecte dans la variable utilisée dans le HTML
          this.list = data[code].rows;


          //on affiche les éléments à afficher sur la grid
          menu.forEach((item) => {

            if (item.code === code) {
              this.grid = item.grid;

            }
          });


        }

      });


    });


  }

  async openData(id) {

    console.log('ouverture de la fiche', id);

    const popover = await this.popoverController.create({
      component: WiewDataComponent,
      cssClass: 'wiewDataComponent-popover',
      showBackdrop: true,
      componentProps: {entity: this.entity, mode:'update', code: this.code, idData: id}
    });
    return await popover.present();

  }


  async deleteData(uniqueId) {

    console.log('delete data', uniqueId );

    const popoverConfir = await this.popoverController.create({
      component: ConfirmationComponent,
      cssClass: 'confirmationComponent-popover',
      // showBackdrop: true,
    });

    await popoverConfir.present();

    popoverConfir.onWillDismiss().then(
    (value) => {
      console.log('je suis dnas le retour de ma promese');

    const confirmation = value.data.choice;

      if (confirmation === true) {
        this.getListService.deleteData(this.entity, uniqueId).then(
          (val) => {

            this.getListService.getList(this.entity);
            this.toolsService.toast('DASHBOARD.SUCCESS_DELETE', 'success');
          },
          (error) => {
            console.log(error);
            this.toolsService.toast('DASHBOARD.FAIL_DELETE', 'danger');
          }
        );
      }


    }
    );


  }
}
