import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiHelperService } from '../services/api-helper.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private apiHelper: ApiHelperService
    ){}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authHeader = this.apiHelper.getAuthHeader();

    if (authHeader !== null){
        return next.handle(httpRequest.clone({ setHeaders: authHeader }));
    }
    
    return next.handle(httpRequest);
  }
}
