import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {menu} from "../types/menuItem.type";
import {ApiHelperService} from "./api-helper.service";
import {UserStorageService} from "./user-storage.service";
import {User} from "../types/user.type";

@Injectable({
  providedIn: 'root'
})
export class GetListService {

  dataListSubject = new BehaviorSubject<any[]>([]);
  entityActifSubject = new BehaviorSubject<string>('user');
  codeActifSubject = new BehaviorSubject<string>('FOLDER');
  currentUser: User | null;

  constructor(
    private httpClient: HttpClient,
    private userStorageService: UserStorageService,
    private apiHelperService: ApiHelperService
  ) {

    this.userStorageService.getUser().subscribe((user) => {
      this.currentUser = user;
    });
  }


  generateUrlParams(config) {

    /** Attention fonction dans le scope d'une autre fonction
     * Ajout & entre deux chaines pour params get de url
     *
     * @param p
     */
    const addAndSymbol = (p) => {
      if (p.length !== 0) {
        return p += '&';
      }
    };

    let params = '';

    if (config.page) {
      params += 'page=' + config.page;
    }


    if (config.page_size) {
      params = addAndSymbol(params);
      params += 'page_size=' + config.page_size;
    }

    if (config.search) {

      //Cette condition permet de vérifier que l'objet n'est pas vide, c'est a dire  config.search !== {}
      if (Object.keys(config.search).length !== 0 && config.search.constructor === Object) {
        console.warn(config.search);
        params = addAndSymbol(params);
        params += 'search=' + JSON.stringify(config.search);
      }

    }


    if (config.filter) {
      params = addAndSymbol(params);
      params += 'filter=' + JSON.stringify(config.filter);
    }

    if (config.order) {
      params = addAndSymbol(params);
      if(JSON.stringify(config.order).includes('file.')){
        params += 'order=' + JSON.stringify(config.order).replace('file.', '');
      } else {
        params += 'order=' + JSON.stringify(config.order);
      }
    }

    if (config.limit) {
      params = addAndSymbol(params);
      params += 'limit=' + config.limit;
    }

    if (params.length !== 0) {
      params = '?' + params;
    }

    return params;
  }

  /**
   *  permet de recupérer des listes paginer de toutes les entites
   *
   * @param entity : string nom de l'entity ex user
   * @param config
   *  config = {
   *         page: 1,
   *         page_size: 10,
   *         search: {firstname: 'fulla'},
   *         filter:{ lastname: 'Fulla'}
   *       };
   * @param limit
   */
  getList(entity, config: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {

      const code = this.codeActifSubject.getValue();
      // const search = {search: {...this.getSearchSaveInObservable(code), ...this.getGridParams(code)}};

      config = {
        ...{page: this.getPageSaveInObservable(code)},
        ...{search: {...this.getSearchSaveInObservable(code), ...this.getGridParams(code, 'gridSearch')}},
        ...{filter: {...this.getFilterInObservable(code), ...this.getGridParams(code, 'gridFilter')}},
        order: {...config.order}
      };
      Object.assign(config, {limit : environment.limitPagination});

      let url = environment.apiUrl + '/list/' + entity + '/search/paged/' + this.generateUrlParams(config);

      if(this.ifSpecific(code)) {
        menu.forEach((dataInmenu) => {

          if (dataInmenu.code === code) {
            return url = environment.apiUrl + '/'+ dataInmenu.apiLink + this.generateUrlParams(config);
          }
        });
      }

      this.httpClient.get(url).subscribe(
        (response) => {


          //rend les listes accessible partout dans le code.
          this.updateListInObservable(code, response);

          resolve(response);

        }, (error) => {

          console.log(error);
          reject(error);
        });

    });
  }

  /**
   * REtourne le filtre sauvegarder dans l'observable
   * @param entity
   */
  getFilterInObservable(code) {

    if (this.dataListSubject.getValue()[code]) {

      return this.dataListSubject.getValue()[code].filter || '';
    } else {

      return '';
    }

  }

  ifSpecific(code) {
    let isSpecific = false;
    menu.forEach((dataInmenu) => {

      if (dataInmenu.code === code) {
        isSpecific = dataInmenu.link !== '/dashboard';
      }
    });

    return isSpecific;
  }

  /***
   * Permet de mettre les datas d'une liste paginnée dans un subject afin de les rendre accessible partout
   * @param entity
   * @param response
   */
  updateListInObservable(code, response): void {

    //on ajoute la page et la page size dans l'observable
    Object.assign(response, {page: 1});
    //TODO mettre le page_size dans une variable d'env
    Object.assign(response, {page_size: 5});

    //on reinclut le filtre dans la réponse
    Object.assign(response, {filter: this.getFilterInObservable(code)});

    //on réinclut la search dans la raponse
    Object.assign(response, {search: this.getSearchSaveInObservable(code)});

    //on utilise l'entity comme key
    const data = [];
    data[code] = response;

    //on merge les data déja présente dans subject avec le nouvelle appel
    const merge = {...this.dataListSubject.getValue(), ...data};


    //on envoie l'ensemble des nouvelles data
    this.dataListSubject.next(merge);

  }

  /**
   * REtourne la page sauvegarder dans l'observable sinon retourne 1
   * @param code
   */
  getPageSaveInObservable(code) {

    if (this.dataListSubject.getValue()[code]) {

      return this.dataListSubject.getValue()[code].page || 1;
    } else {

      return 1;
    }

  }

  /**
   * REtourne la condition pour filtrer la vue
   */
  getGridParams(code, type) {

    let params = '';
    if (type.toLowerCase() === 'gridfilter') {
      params = 'gridFilter';
    } else if ((type.toLowerCase() === 'gridsearch')) {
      params = 'gridSearch';
    } else {
      console.log('Type non reconnu dans getGridParams');
      return '';
    }

    let condition = {};
    menu.forEach((dataInmenu) => {

      if (dataInmenu.code === code) {

        condition = this.controlAndChangeTemplateValue(dataInmenu[params]);

      }
    });

    return condition;

  }

  controlAndChangeTemplateValue(gridParams) {

    const returnObject = {};

    for (const [key, value] of Object.entries(gridParams)) {

      //vérifie le TemplateValue dans un attribut simple
      if (value.toString().includes('#currentUserData#')) {

        console.log('TemplateKey simple');
        Object.assign(returnObject, {[key]: this.replaceTemplateValue(key)});

      }
      //vérifie le TemplateValue dans un attribut dans une relationship
      else if (Object.values(value).includes('#currentUserData#')) {

        console.log('TemplateKey relation');


        const childKey = Object.keys(value).toString();

        console.log(key);
        console.log(childKey);
        console.log(value);

        //on reconstuit l'a hierachie de l'objet
        Object.assign(returnObject, {[key]: {[childKey]: this.replaceTemplateValue(key, childKey)}});


      } else {
        console.log('classique');

        Object.assign(returnObject, {[key]: value});


      }
    }

    return returnObject;
  }

  replaceTemplateValue(templateKey, chilkey = null) {


    if (this.currentUser[templateKey] && typeof this.currentUser[templateKey] === 'string') {

      console.log('je remplace templateKey avec la val');

      return this.currentUser[templateKey];

    } else if (this.currentUser[templateKey] && typeof this.currentUser[templateKey] === 'object') {
      console.log('relationship');

      const returnArray = [];
      this.currentUser[templateKey].forEach((el, index, array) => {

        returnArray.push(el[chilkey]);

      });

      return returnArray;

    } else {
      return '';
    }


  }


  /**
   * REtourne la recherche sauvegarder dans l'observable sinon retourne 1
   * @param entity
   */
  getSearchSaveInObservable(code) {

    if (this.dataListSubject.getValue()[code]) {
      return this.dataListSubject.getValue()[code].search || '';
    } else {

      return '';
    }

  }

  getTypeData(entity, uniqueId) {
    let body = {params: {}};
    if(uniqueId) {
      body = {params: {uniqueId}};
    }

    return new Promise((resolve, reject) => {
      this.httpClient.get(this.apiHelperService.getUrl('/list/' + entity + '/getHtmlInformation/'), body).subscribe(
        (response) => {

          resolve(response);

        }, (error) => {

          console.log(error);
          reject(error);
        });

    });
  }

  /**
   * Récupere du back le list des options pour une html list 'select'
   */
  getOptionsListParams(entity, key) {

    return new Promise((resolve, reject) => {
      this.httpClient.get(this.apiHelperService.getUrl('/list/' + entity + '/getHtmlInformation/paramsoptions'), {params: {attribute: key}}).subscribe(
        (response) => {

          resolve(response);

        }, (error) => {

          console.log(error);
          reject(error);
        });

    });
  }

  /**
   * Récupere  le nom du code et label pour un champs
   */
  getCodeAndLabel(entity, key) {

    return new Promise((resolve, reject) => {
      this.httpClient.get(this.apiHelperService.getUrl('/list/' + entity + '/getHtmlInformation/codelabel'), {params: {attribute: key}}).subscribe(
        (response) => {

          resolve(response);

        }, (error) => {

          console.log(error);
          reject(error);
        });

    });
  }

  getEntityByCode(code) {

    let entity = '';


    menu.forEach((dataInMenu) => {

      if (dataInMenu.code === code) {
        entity = dataInMenu.entity;
      }
    });


    return entity;

  }

  deleteData(entity, uniqueId) {

    let route = this.apiHelperService.getUrl('/delete/' + entity + '/' + uniqueId);

    if (entity.toLowerCase() === 'user') {
      route = this.apiHelperService.getUrl(entity + '/' + uniqueId);
    }

    return new Promise((resolve, reject) => {
      this.httpClient.delete(route).subscribe(
        (response) => {

          resolve(response);


        }, (error) => {

          console.log(error);
          reject(error);
        });

    });

  }

  updateData(entity, uniqueId, objectTosave) {

    if(entity === 'user') {
      //force to save user as commercials
      objectTosave.role = ['c1ae948e-c2c9-4566-a8f4-f5c0532536b5'];
    }


    return new Promise((resolve, reject) => {
      // console.log(objectTosave);
      this.httpClient.put(this.apiHelperService.getUrl(this.getUpdateRoute(entity)), objectTosave).subscribe(
        (response) => {

          resolve(response);


        }, (error) => {

          console.log(error);
          reject(error);
        });

    });

  }

  getAttributEntity(entity) {
    return new Promise((resolve, reject) => {

      this.httpClient.get(this.apiHelperService.getUrl('/list/' + entity + '/getHtmlInformation/attribute')).subscribe(
        (response) => {
          resolve(response);

        }, (error) => {

          console.log(error);
          reject(error);
        });

    });

  }

  private getUpdateRoute(entity: string): string {
    if (entity === "user") {
      return "/user";
    } else {
      return `/set/${entity}`;
    }
  }
}
