import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor() { }

  static getTranslationLoader() {
    return {
      provide: TranslateLoader,
      useFactory: this.HttpLoaderFactory,
      deps: [HttpClient]
    };
  }

  static HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
  }

}
