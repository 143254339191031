import {Component, OnInit} from '@angular/core';
import {MonthWorkType} from '../../types/monthWork.type';
import {PopoverController} from '@ionic/angular';

@Component({
  selector: 'app-work-modal',
  templateUrl: './work-modal.component.html',
  styleUrls: ['./work-modal.component.scss'],
})
export class WorkModalComponent implements OnInit{

  dataArray;
  // unitNumber;
  selectedUnitNumber;
  selectedYear = 0;
  monthsType = MonthWorkType;
  years = [0, 1, 2, 3, 4, 5];


  constructor(
    private popoverController: PopoverController
  ) {}

  ngOnInit() {
    if(!this.dataArray[this.selectedUnitNumber]) {
      this.dataArray[this.selectedUnitNumber] = [];
    }
    if(this.dataArray[this.selectedUnitNumber].length === 0){
      this.years.forEach(year => {
        this.dataArray[this.selectedUnitNumber][year] = [];
      });
    } else {
      this.displayData();
    }

  }


  changeYear($event) {
    this.selectedYear = $event.target.value;
    this.displayData();
  }

  displayData() {
    setTimeout(() => { //let the time to html to display
      if (this.dataArray[this.selectedUnitNumber][this.selectedYear].length > 0) {
        let index = 0;
        this.dataArray[this.selectedUnitNumber][this.selectedYear].forEach(checkedMonth => {
          if (checkedMonth) {
            // @ts-ignore
            (<HTMLInputElement>document.querySelectorAll('.month_checkbox')[index]).checked = true;
          }
          index++;
        });
      }
      // this.dataArray[this.selectedUnitNumber][this.selectedYear] = [];
    }, 500);
  }

  getCheckboxData() {

    for( const input of document.querySelectorAll('.month_checkbox')){
      if(input.querySelector('input')){
        if(this.dataArray[this.selectedUnitNumber][this.selectedYear].length === 12) {
          //we erase actual data and rewrite them
         this.dataArray[this.selectedUnitNumber][this.selectedYear].splice(0);
        }
        this.dataArray[this.selectedUnitNumber][this.selectedYear].push(input.querySelector('input').value);
      }
    }

  }

  dismiss() {
    this.getCheckboxData();
    return this.popoverController.dismiss(this.dataArray);

  }
}
