import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiHelperService } from '../services/api-helper.service';
import { catchError, filter, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { UserStorageService } from '../services/user-storage.service';
import { PopoverController } from '@ionic/angular';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private userStorageService: UserStorageService,
        private popoverController: PopoverController
    ){}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(httpRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && ! ["/login", "/"].includes(this.router.url)){
          this.userStorageService.setUser(null);
          this.popoverController.dismiss()
          .then(result => {})
          .catch(err => {});
          this.router.navigate(["/login"]);
        }
        return throwError(error);
      })
    );
  }
}
