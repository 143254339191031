import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { UserStorageService } from './services/user-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private userStorageService: UserStorageService
  ) {}

  ngOnInit(): void {
    this.authService.getProfile()
    .subscribe(user => {
      this.userStorageService.setUser(user);
    });
  }
}
