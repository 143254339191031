import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  	constructor(
		private httpClient: HttpClient,
  	) { }

  	getusersList(): Promise<any> {

        return new Promise((resolve, reject) => {

            this.httpClient
                .get(environment.apiUrl + '/list/user/all/paged')
                .subscribe(
                    response => {
                        resolve(response);
                    },

                    error => {
                        reject(error);
                    }
                );
        });

    }

    updateUser(userObject): Promise<any> {

        return new Promise((resolve, reject) => {

            this.httpClient
                .put(environment.apiUrl + '/user', userObject)
                .subscribe(
                    response => {
                        resolve(response);
                    },

                    error => {
                        reject(error);
                    }
                );
        });

    }

}
