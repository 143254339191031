export const menu =
  [

    {
      title: 'Utilisateurs',
      entity: 'user',
      code: 'ALLUSER',
      icon: 'people',
      link: '/dashboard',
      gridFilter: {},
      gridSearch: {},
      grid: ['lastname', 'firstname', 'phone', 'mail',  'created_at',  'last_connection_at'],
      whereDoSearch: ['lastname','mail'], //Attention on ne peux faire de recherche si attribut déja utiliser dans le gridFilter,
      allowedRoles: ['ADMIN']
    },
    {
      title: 'User Filtre',
      entity: 'user',
      code: 'FILTERUSER',
      icon: 'people',
      link: '/dashboard',
      gridFilter: {} ,
      gridSearch: {role: { unique_id : '#currentUserData#'}, },
      grid: ['firstname', 'lastname', 'mail', 'created_at'],
      whereDoSearch: ['mail'],
      allowedRoles: ['BLOK']
    },
    {
      title: 'Role',
      entity: 'role',
      code: 'ROLE',
      icon: 'people',
      link: '/dashboard',
      gridFilter:  {},
      gridSearch: {},
      grid: ['code', 'label'],
      whereDoSearch: ['label'],
      allowedRoles: ['BLOK']

    },
    {
      title: 'Dossier',
      entity: 'file',
      code: 'FILE',
      icon: 'FOLDER',
      link: '/folder', //If not dashboard, is specific, faire le service
      apiLink : 'file/list',
      gridFilter:  {},
      gridSearch: {},
      grid: ['name', 'location.address_1', 'location.city', 'status.name', 'general_info.typology'],
      whereDoSearch: ['unique_id', 'location.address_1', 'status.name', 'location.city', 'location.zipcode']
    },
    {
      title: 'Message',
      entity: '',
      code: '',
      icon: 'mail',
      link: '/Message',
      gridFilter: {},
      gridSearch: {},
      grid: [],
      whereDoSearch: [''],
      allowedRoles: ['BLOK']

    },



  ];
