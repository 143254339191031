import {Component, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {

  constructor(private popoverController: PopoverController,) {
  }

  ngOnInit() {
  }

  async checkChoice(resultChoice: boolean) {


    await this.popoverController.dismiss(
      {choice: resultChoice}
    );


  }

}
