import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {ResultComponentComponent} from './components/dashboard/result-component/result-component.component';

//component
import {StrongPasswordComponent} from './components/strong-password/strong-password.component';
import {WiewDataComponent} from './popover/wiew-data/wiew-data.component';
import {ConfirmationComponent} from './popover/confirmation/confirmation.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import { ProfilComponent } from './popover/profil/profil.component';
import {ReplacePipe} from './pipes/replace.pipe';
import {WorkModalComponent} from './components/work-modal/work-modal.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
  ],
  declarations: [
    ResultComponentComponent,
    StrongPasswordComponent,
    WiewDataComponent,
    ConfirmationComponent,
    ProfilComponent,
    WorkModalComponent,
    ReplacePipe
  ],
  exports: [
    // Modules
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,

    // Components
    ResultComponentComponent,
    StrongPasswordComponent,
    ReplacePipe,

  ],
})
export class SharedModule {
}
