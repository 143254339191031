import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {ModalController, Platform} from "@ionic/angular";
import {UserStorageService} from "./user-storage.service";
import {environment} from '../../environments/environment';

// import { CookieService } from "ngx-cookie-service";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError, distinctUntilChanged, map, mapTo, mergeMap} from "rxjs/operators";
import {User} from "../types/user.type";
import {ApiHelperService} from "./api-helper.service";
import {SharedModule} from "../shared.module";

// import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class AuthService {

  passwordRuleSecu = {
    hasnumber: {regex: /[0-9]/},
    hasMaj: {regex: /[A-Z]/},
    hasMin: {regex: /[a-z]/},
    hasSpecial: {regex: /[$&+,:;=?@#|'<>.^*()%!-]/},
    size: {size: 8},
  };
  private authenticated = new BehaviorSubject(false);
  private codeOK = new BehaviorSubject(false);

  constructor(
    //   private cookieService: CookieService,
    private modalController: ModalController,
    private http: HttpClient,
    private platform: Platform,
    private router: Router,
    private userStorageService: UserStorageService,
    private apiHelper: ApiHelperService
  ) {
    // this.platform.ready().then(() => {
    //   this.authenticated.next(this.isUserAuthenticated());
    // });
  }

  login(mail: string, password: string): Observable<User | null> {
    const url = this.apiHelper.getUrl("/auth/login");

    let formData = new FormData();
    formData.append("username", mail);
    formData.append("password", password);

    return this.http.post<any>(url, formData);
  }

  logout() {
    const url = this.apiHelper.getUrl("/auth/logout");

    this.http.post<any>(url, null).subscribe({
      next: () => {
        this.userStorageService.setUser(null);
        this.router.navigate(["/login"]);
      }
    });
  }

  getProfile(): Observable<User | null> {
    const url = this.apiHelper.getUrl("/auth/profile");

    return this.http.get<User | null>(url)
      .pipe<User | null>(catchError(err => {
        return of(null);
      }));
  }

  isAuthenticated(): Observable<boolean> {
    if (localStorage.getItem("token") === null) {
      return of(false);
    }
    return this.getProfile().pipe(map(user => {
      if (user === null) {
        console.log("remove");
        localStorage.removeItem("token");
        return false;
      }
      return true;
    }));
  }

//   private isUserAuthenticated(): boolean {
//     return this.cookieService.check(environment.authCookie);
//   }

//   getAuthenticationInfo(): MargaretAuthenticationInfo {
//     const authenticationInfoCookie = this.cookieService.get(
//         environment.authCookie
//     );

//     if(authenticationInfoCookie.length > 0) {
//       return JSON.parse(atob(authenticationInfoCookie));
//     } else {
//       return ;
//     }
//   }

//   private storeAuthenticationInfo(
//       authenticationInfo: MargaretAuthenticationInfo
//   ) {
//     const cookieContent: string = btoa(JSON.stringify(authenticationInfo));
//     const cookieExpirationDate: Date = this.getTokenExpirationDate(
//         authenticationInfo.token
//     );
//     const cookiePath = "/";
//     this.cookieService.set(
//         environment.authCookie,
//         cookieContent,
//         cookieExpirationDate,
//         cookiePath
//     );
//     this.authenticated.next(true);
//   }

  getTokenExpirationDate(token: string): Date {
    const tokenExpirationTimestamp = JSON.parse(atob(token.split('.')[1])).exp;
    return new Date(tokenExpirationTimestamp * 1000);
  }

//   removeAuthenticationInfo() {
//     this.cookieService.delete(environment.authCookie, '/');
//     this.authenticated.next(false);
//     this.router.navigate(["/"]);
//   }

//   login(credentials: DashboardCredentials): Promise<MargaretAuthenticationInfo> {
//     let xthis = this;
//     return new Promise((resolve, reject) => {

//       this.httpClient
//           .post(environment.apiUrl + "/login", credentials)
//           .subscribe(
//               (response) => {

//                 const loginInfo: any = { ...response };

//                 const authenticationInfo: MargaretAuthenticationInfo = {
//                   mail: loginInfo.user.mail,
//                   firstname: loginInfo.user.firstname,
//                   lastname: loginInfo.user.lastname,
//                   organization_id: loginInfo.user.organization_id,
//                   organization: loginInfo.user.organization,
//                   isOrgAdmin : loginInfo.user.isOrgAdmin,
//                   double_auth : loginInfo.user.double_auth,
//                   code: loginInfo.user.code,
//                   token: loginInfo.token,
//                 };

//                 // this.storeAuthenticationInfo(authenticationInfo);

//                 // if (this.cookieService.get(environment.redirectToCookie) !== undefined) {
//                 //   this.router.navigate([
//                 //     "/" + this.cookieService.get(environment.redirectToCookie),
//                 //   ]);
//                 //   this.cookieService.delete(environment.redirectToCookie);
//                 // }
//                 if(authenticationInfo.code != 'VISITOR') {
//                   this.router.navigate(["/pro/dashboard"]);
//                 } else {
//                   this.router.navigate(["/"]);
//                 }
//                 resolve(authenticationInfo);
//               },
//               (error) => {
//                 reject(error);
//               }
//           );
//     });
//   }

//   logout(): Promise<any> {
//     return new Promise((resolve, reject) => {
//       this.httpClient.post(environment.apiUrl + "/logout", {}).subscribe(
//           (response) => {
//             // this.removeAuthenticationInfo();
//             resolve(response);
//           },
//           (error) => {
//             // this.removeAuthenticationInfo();
//             reject(error);
//           }, () => {
//             // this.removeAuthenticationInfo();
//           }
//       );
//     });
//   }

//   requestPasswordReset(mail: string): Promise<any> {
//     return new Promise((resolve, reject) => {
//       this.httpClient
//           .post(environment.apiUrl + "/forgot-password", { mail })
//           .subscribe(
//               (response) => {
//                 return resolve(response);
//               },
//               (error) => {
//                 return reject(error);
//               }
//           );
//     });
//   }

  forgotPassword(mailTo): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiUrl + "/user/forgot-password", {
          mail: mailTo
        })
        .subscribe(
          (response) => {
            return resolve(response);
          },
          (error) => {
            return reject(error);
          }
        );
    });
  }

  resetPassword(token: String, new_password: String): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiUrl + "/user/reset-password", {
          token: token,
          newPassword: new_password,
        })
        .subscribe(
          (response) => {
            return resolve(response);
          },
          (error) => {
            return reject(error);
          }
        );
    });
  }


  /**
   *  #####
   *  Gestion du mot de passe
   *  ####
   */

  hasNumber(pass) {

    if (pass && pass.match(this.passwordRuleSecu.hasnumber.regex)) {

      return true;
    }
    return false;
  }

  hasMaj(pass: string) {

    if (pass && pass.match(this.passwordRuleSecu.hasMaj.regex)) {

      return true;
    }
    return false;
  }

  hasMin(pass: string) {

    if (pass && pass.match(this.passwordRuleSecu.hasMin.regex)) {

      return true;
    }
    return false;
  }

  hasSpecial(pass) {

    if (pass && pass.match(this.passwordRuleSecu.hasSpecial.regex)) {

      return true;
    }
    return false;
  }

  lenght(pass: string) {

    if (pass && pass.toString().length >= this.passwordRuleSecu.size.size) {

      return true;
    }
    return false;
  }

  /**
   * Test si le mot de passe respecte la sécurisation
   * @param pass
   */
  validPassword(pass: string) {

    let valid = true;

    valid = this.hasNumber(pass);
    valid = this.hasMin(pass);
    valid = this.hasMaj(pass);
    valid = this.hasSpecial(pass);
    valid = this.lenght(pass);

    return valid;
  }

  testActualPassWord(actualPassword: string) {

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiUrl + '/auth/controlActualPassword', {
          token: localStorage.getItem('token'),
          actualPassword,
        })
        .subscribe(
          (response: any) => {
            if (response.success) {
              return resolve(true);
            } else {
              return resolve(false);
            }


          },
          (error) => reject(error)
        );
    });

  }

  changePassword(actualPassword, newPassword){

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiUrl + '/auth/changePassword', {
          token: localStorage.getItem('token'),
          actualPassword,
          newPassword
        })
        .subscribe(
          (response: any) => {
            if (response.success) {
              return resolve(true);
            } else {
              return resolve(false);
            }


          },
          (error) => reject(error)
        );
    });
  }
}
