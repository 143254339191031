import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'replace' })

export class ReplacePipe implements PipeTransform {
  transform(value: string | undefined): string | undefined {
    if (value === undefined) {
      return 'undefined';
    } else {
      return value.replace(/\s/g, '');
    }
  }
}
