import {Injectable} from '@angular/core';

import {ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';


@Injectable({ providedIn: 'root' })


export class ToolsService {


  constructor(
    public toastController: ToastController,
    public translate: TranslateService,
    public location: Location
  ) {
  }



  /** Display a toast messsage
   *  color : "primary", "secondary", "tertiary", "success", "warning", "danger", "light", "medium", and "dark"
   *
   * @param message
   * @param color
   * @param duration
   */
  async toast(message, color = 'primary', duration: number = 4000) {

    await this.translate.get(message).subscribe(
      response => {
        message = response;
      },
      err => {
        console.log(err);
      }
    );

    const toast = await this.toastController.create({
      message,
      cssClass: 'toast-message animate__animated animate__slideInDown ',
      duration,
      position: 'top',
      color,
    });

    toast.present();
  }



}
