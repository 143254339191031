import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserStorageService } from './user-storage.service';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiHelperService {

  private authHeaderMethod;

  constructor(
    private userStorageService: UserStorageService
  ) {
    this.authHeaderMethod = 'Bearer';
  }

  public getUrl(path: string): string {
    return new URL(path, environment.apiUrl).href;
  }

  public getAuthHeader(): {
    Authorization: string;
  } | null {
    let token = localStorage.getItem("token");

    if (token !== null){
      return {
        Authorization: `${this.authHeaderMethod} ${token}`
      }
    }

    return null;
  }
}
